.top-floating-header-header-div {
  width: calc(100vw - 26.04vw) !important;
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  position: fixed;
  z-index: 3500;

}
.top-floating-header-header-logo-div {
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 20px;
  z-index: 3500;

}
.top-floating-header-fixed-logo {
  height: 30px;
    z-index: 3500;

}
.top-floating-header-links-div {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  justify-content: flex-end;
  align-items: center;
  height: 30px;
    z-index: 3500;

}
.top-floating-header-links-div a {
  color: #fdfdfd;
  font-size: 16px;
  width: max-content;
  line-height: 0;
  font-weight: 500;
  z-index: 3500;

}
.top-floating-header-links-div a:hover {
  opacity: 80%;
}
.top-floating-header-links-div a:focus {
  opacity: 80%;
}
.top-floating-header-drawer-button,
.top-floating-header-drawer-button-links {
  z-index: 3500;
  display: flex !important;
  height: 40px !important;
  width: 40px !important;
  border-radius: 100% !important;
  color: #fdfdfd !important;
  margin: 0 !important;
  padding: 4px !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: flex-end !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  background-color: #fdfdfd !important;
}

.top-floating-header-drawer-button {
  display: none !important;
}
.top-floating-header-header-div .top-floating-header-drawer-button-links {
  margin-left: 100px !important;
  /* margin-left: 0px !important;
  transform: translateX(10vw); */
}
.top-floating-header-drawer-button div,
.top-floating-header-drawer-button-links div {
  background: #3a3632;
  height: 2px;
  width: 100%;
  margin: 3px 0;
}
.top-floating-header-drawer-button .side-drawer-button-container-div-line2,
.top-floating-header-drawer-button-links
  .side-drawer-button-container-div-line2 {
  width: 60%;
}
.top-floating-header-drawer-button:hover,
.top-floating-header-drawer-button:focus,
.top-floating-header-drawer-button-links:hover,
.top-floating-header-drawer-button-links:focus {
  /* background-color: #fdfdfd !important;
  color: #fdfdfd !important; */
  border: 1px solid #fdfdfd !important;
}

.top-floating-header-drawer-button-black,
.top-floating-header-drawer-button-links-black {
  color: #3a3632 !important;
  background-color: #3a3632 !important;
}
.top-floating-header-drawer-button-black div,
.top-floating-header-drawer-button-links-black div {
  background: #fdfdfd;
}
.top-floating-header-drawer-button-black:hover,
.top-floating-header-drawer-button-black:focus,
.top-floating-header-drawer-button-links-black:hover,
.top-floating-header-drawer-button-links-black:focus {
  background-color: #3a3632 !important;
  color: #3a3632 !important;
  border: 1px solid #3a3632!important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .top-floating-header-header-div {
    width: calc(100vw - 13.02vw) !important;
    top: 5vh !important;
    top: 3.315vh !important;
    left: 6.51vw !important;
  }
  .top-floating-header-fixed-logo {
    height: 20px;
  }
  .top-floating-header-links-div {
    display: none !important;
  }
  .top-floating-header-drawer-button {
    display: flex !important;
  }
}

@media only screen and (max-width: 600px) and (orientation: landscape) {
  .top-floating-header-header-div {
    width: calc(100vw - 13.02vh) !important;
    top: 3.315vw !important;
    left: 6.51vh !important;
  }
  .top-floating-header-links-div {
    display: none !important;
  }
  .top-floating-header-drawer-button {
    display: flex !important;
  }
  .top-floating-header-fixed-logo {
    height: 25px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .top-floating-header-header-div {
    width: calc(100vw - 16.276vw) !important;
    top: 30px !important;
    left: 8.138vw !important;
  }
  .top-floating-header-links-div {
    display: none !important;
  }
  .top-floating-header-drawer-button {
    display: flex !important;
  }
}

@media only screen and (min-width: 600px) and (orientation: landscape) {
  .top-floating-header-header-div {
    width: calc(100vw - 16.276vh) !important;
    top: 3.978vw !important;
    left: 8.138vh !important;
  }
  .top-floating-header-links-div {
    display: none !important;
  }
  .top-floating-header-drawer-button {
    display: flex !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .top-floating-header-header-div {
    width: calc(100vw - 19.53vw) !important;
    top: 5.5vh !important;
    left: 9.765vw !important;
  }
  .top-floating-header-links-div {
    display: none !important;
  }
  .top-floating-header-drawer-button {
    display: flex !important;
  }
}
@media only screen and (min-width: 768px) and (orientation: landscape) {
  .top-floating-header-header-div {
    width: calc(100vw - 16.276vh) !important;
    top: 4.641vw !important;
    left: 8.138vh !important;
  }
  .top-floating-header-links-div {
    display: none !important;
  }
  .top-floating-header-drawer-button {
    display: flex !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1050px) and (max-width:1200px) {
  .top-floating-header-header-div {
    width: calc(100vw - 22.786vw) !important;
    top: 5.305vh !important;
    left: 11.393vw !important;
  }
  .top-floating-header-links-div {
    display: flex !important;
    margin-left:100px !important;
    margin-right:10px !important;
    gap:30px;
  }
  .top-floating-header-drawer-button-links {
    display:flex !important;
    margin-right: 100px !important;
  }
  .top-floating-header-drawer-button {
    display: none !important;
  }
  /* .top-floating-header-fixed-logo {
    height: 3.978vh !important;
  } */
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px)and (max-width:1300px) {
  .top-floating-header-header-div {
    width: calc(100vw - 15.04vw) !important;
    left: 13.02vw !important;
    top: 6.631vh !important;
  }
  .top-floating-header-links-div {
    display: flex !important;
    margin-left:50px !important;
  }
  .top-floating-header-drawer-button-links {
    margin-left: 100px !important;
  }
  .top-floating-header-drawer-button {
    display: none !important;
  }
}

@media only screen and (min-width: 1300px){
  .top-floating-header-header-div {
    width: calc(100vw - 20.04vw) !important;
    left: 13.02vw !important;
    top: 6.631vh !important;
  }
  .top-floating-header-links-div {
    display: flex !important;
    margin-left: 100px;
  }
  .top-floating-header-drawer-button-links {
    margin-left: 100px !important;
  }
  .top-floating-header-drawer-button {
    display: none !important;
  }
}

.top-floating-header-hidden-links-div {
  animation-name: myAnimation !important;
  animation-duration: 500ms !important;
  animation-fill-mode: forwards !important;
}
@keyframes myAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
