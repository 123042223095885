.terms-of-use-content {
  width: 100% !important;
  padding: 6.631vh 13.02vw !important;
  background-color: #f4f4f4;
}
.terms-of-use-content-container {
  padding: 50px;
  background-color: #fdfdfd;
  border-radius: 40px;
}
.terms-of-use-content-container .h4-subheading {
  padding-top: 20px;
}
.terms-of-use-content ul {
  list-style: circle;
}
.terms-of-use-content li {
  margin-bottom: 0.25rem;
}
.terms-of-use .h4-subheading {
  margin-top: 40px !important;
  font-weight: 600;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .terms-of-use-content {
    padding: 3.315vh 6.51vw !important;
  }
  .terms-of-use-content-container {
    padding: 20px !important;
    border-radius: 20px !important;
  }
}
@media only screen and (max-width: 600px) and (orientation: landscape) {
  .terms-of-use-content {
    padding: 3.315vw 6.51vh !important;
  }
  .terms-of-use-content-container {
    padding: 20px !important;
    border-radius: 20px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .terms-of-use-content {
    padding: 3.978vh 8.138vw !important;
  }
  .terms-of-use-content-container {
    padding: 30px !important;
    border-radius: 20px !important;
  }
}
@media only screen and (min-width: 600px) and (orientation: landscape) {
  .terms-of-use-content {
    padding: 3.978vw 8.138vh !important;
  }
  .terms-of-use-content-container {
    padding: 30px !important;
    border-radius: 20px !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .terms-of-use-content {
    padding: 4.641vh 9.765vw !important;
  }
  .terms-of-use-content-container {
    padding: 40px !important;
    border-radius: 30px !important;
  }
}
@media only screen and (min-width: 768px) and (orientation: landscape) {
  .terms-of-use-content {
    padding: 4.641vw 9.765vh !important;
  }
  .terms-of-use-content-container {
    padding: 40px !important;
    border-radius: 30px !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .terms-of-use-content {
    padding: 5.305vh 11.393vw !important;
  }
  .terms-of-use-content-container {
    padding: 40px !important;
    border-radius: 30px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .terms-of-use-content {
    padding: 6.631vh 13.02vw !important;
  }
  .terms-of-use-content-container {
    padding: 50px !important;
    border-radius: 50px !important;
  }
}
