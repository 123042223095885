.about-mission {
  width: 100% !important;
  padding: 6.631vh 13.02vw !important;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: #fdfdfd;
}
.about-mission img {
  width: 100%;
}
.about-mission .h2 {
  margin-top: 5px;
}
.about-mission-left,
.about-mission-right {
  width: 46%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  background-color: #f4f4f4;
  border-radius: 10px;
}
.about-mission-left p,
.about-mission-right p {
  padding: 0 20px;
}
.about-mission-right {
  margin-top: 100px;
}
.about-mission-left {
  margin-bottom: 100px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .about-mission {
    padding: 3.315vh 6.51vw !important;
    align-items: flex-end !important;
    flex-direction: column;
    gap: 30px;
  }
  .about-mission-left,
  .about-mission-right {
    width: 100%;
  }
  .about-mission-right {
    margin-top: 0;
  }
  .about-mission-left {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 600px) and (orientation:landscape) {
  .about-mission {
    padding: 3.315vw 6.51vh !important;
    align-items: flex-end !important;
    flex-direction: column;
    gap: 30px;
  }
  .about-mission-left,
  .about-mission-right {
    width: 100%;
  }
  .about-mission-right {
    margin-top: 0;
  }
  .about-mission-left {
    margin-bottom: 0;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .about-mission {
    padding: 3.978vh 8.138vw !important;
    flex-direction: column;
    gap: 30px;
  }
  .about-mission-left,
  .about-mission-right {
    width: 100%;
  }
  .about-mission-right {
    margin-top: 0;
  }
  .about-mission-left {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 600px) and (orientation: landscape) {
  .about-mission {
    padding: 3.978vw 8.138vh !important;
    flex-direction: row;
  }
  .about-mission-left,
  .about-mission-right {
    width: 46%;
  }
  .about-mission-right {
    margin-top: 100px;
    transform: translateX(-2.8%);
  }
  .about-mission-left {
    margin-bottom: 100px;
    transform: translateX(2.8%);
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .about-mission {
    padding: 4.641vh 9.765vw !important;
    flex-direction: row;
  }
  .about-mission-left,
  .about-mission-right {
    width: 46%;
  }
  .about-mission-right {
    margin-top: 100px;
    transform: translateX(-2.8%);
  }
  .about-mission-left {
    margin-bottom: 100px;
    transform: translateX(2.8%);
  }
}
@media only screen and (min-width: 768px) and (orientation:landscape){
  .about-mission {
    padding: 4.641vw 9.765vh !important;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .about-mission {
    padding: 5.305vh 11.393vw !important;
    flex-direction: row;
  }
  .about-mission-left,
  .about-mission-right {
    width: 40%;
  }
  .about-mission-right {
    margin-top: 60px;
    transform: translateX(-2.7%);
  }
  .about-mission-left {
    margin-bottom: 60px;
    transform: translateX(2.7%);
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .about-mission {
    padding: 6.631vh 13.02vw !important;
    flex-direction: row;
  }
  .about-mission-left,
  .about-mission-right {
    width: 30%;
  }
  .about-mission-right {
    margin-top: 60px;
    transform: translateX(-2.5%);
  }
  .about-mission-left {
    margin-bottom: 60px;
    transform: translateX(2.5%);
  }
}
