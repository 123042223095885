.DrivingInnovationThroughChange-div {
  min-height: 100vh;
  width: 100% !important;
  padding: 6.631vh 13.02vw !important;
  position: relative;
  background-color: #2c7fbf;
}

.DrivingInnovationThroughChange-content {
  z-index: 2000 !important;
  margin-top: calc(30px + 6.631vh);
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fdfdfd;
}
.DrivingInnovationThroughChange-button {
  z-index: 3000 !important;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: 5px solid transparent;
  width: fit-content;
  color: #fdfdfd;
  /* border: 1px solid #3a3632; */
  border: 1px solid #fdfdfd;
  background-color: transparent !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 12px 50px;
  letter-spacing: 1.5px;
  margin-top: 20px;
  font-weight: 700;
  font-size: 14px;
  background: linear-gradient(to left, transparent 50%, #3a3632 50%) right !important;
  background-size: 200% 100% !important;
  margin-top: 50px;
}

.DrivingInnovationThroughChange-button:hover {
  border: 1px solid #3a3632;
  background-position: left !important;
}

.DrivingInnovationThroughChange-img {
  position: absolute;
  right: 6.631vw;
  bottom: 2vh;
  z-index: 0 !important;
}
.DrivingInnovationThroughChange-img img {
  height: 80vh;
  z-index: 0 !important;
  width: auto;
}
.DrivingInnovationThroughChange-img-mobile {
  position: static;
  width: 100%;
  z-index: 0 !important;
  display: none;
}
.DrivingInnovationThroughChange-img-mobile img {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 767px) {
  .DrivingInnovationThroughChange-div-button {
    margin-top: 300px;
    padding: 12px 50px;
    background: linear-gradient(to left, transparent 50%, #3a3632 50%) right !important;
    background-size: 200% 100% !important;
  }
  .DrivingInnovationThroughChange-button:hover {
  border: 1px solid #3a3632;
    background-position: left !important;
  }
}

.DrivingInnovationThroughChange-inside-div .h4-subheading {
  margin: 0 !important;
  padding: 0 !important;
}
.DrivingInnovationThroughChange-inside-div .h2-heading {
  margin: 0 !important;
  padding: 0 !important;
  margin-bottom: 10px !important;
}
.page1-button-mobile{
  display: none !important;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .DrivingInnovationThroughChange-div {
    padding: 3.315vh 6.51vw !important;
    display: flex;
    position: static;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
  }
  .DrivingInnovationThroughChange-content {
    margin-top: calc(40px + 3.315vh);
  }
  .DrivingInnovationThroughChange-img {
    display: none;
  }
  .DrivingInnovationThroughChange-img-mobile {
    display: block;
    position: static;
    margin-top: 10px;
  }
  .DrivingInnovationThroughChange-img-mobile img {
    width: 99%;
    height: auto;
  }
  .DrivingInnovationThroughChange-button{
    margin-top: 40px;
  }
}
@media only screen and (max-width: 600px) and (orientation: landscape) {
  .DrivingInnovationThroughChange-content {
    margin-top: calc(40px + 3.315vw);
  }
  .DrivingInnovationThroughChange-div {
    padding: 3.315vw 6.51vh !important;
    display: flex;
    position: static;
    flex-direction: column;
    justify-content: center;
    min-height: fit-content;
  }
  .DrivingInnovationThroughChange-img {
    display: none;
  }
  .DrivingInnovationThroughChange-img-mobile {
    display: block;
    position: static;
    margin-top: 10px;
  }
  .DrivingInnovationThroughChange-img-mobile img {
    width: 100%;
    height: auto;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .DrivingInnovationThroughChange-div {
    min-height: 100vh;
    padding: 3.978vh 8.138vw !important;
    display: flex;
    position: static;
    flex-direction: column;
    justify-content: center;
  }
  .DrivingInnovationThroughChange-content {
    margin-top: calc(40px + 3.978vh);
  }
  .DrivingInnovationThroughChange-img {
    display: none;
  }
  .DrivingInnovationThroughChange-img-mobile {
    display: block;
    position: static;
    margin-top: 50px;
  }
  .DrivingInnovationThroughChange-img-mobile img {
    width: 100%;
    height: auto;
  }
}
@media only screen and (min-width: 600px) and (orientation: landscape) {
  .DrivingInnovationThroughChange-div {
    min-height: 100vh;
    max-height: 100vw;
    padding: 3.978vw 8.138vh !important;
    display: flex;
    position: static;
    flex-direction: column;
    justify-content: center;
  }
  .DrivingInnovationThroughChange-content {
    margin-top: calc(40px + 3.978vw);
  }
  .DrivingInnovationThroughChange-img {
    display: none;
    display: block;
    position: static;
  }
  .DrivingInnovationThroughChange-img img {
    width: 100%;
    height: auto;
  }
  .DrivingInnovationThroughChange-img-mobile {
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  DrivingInnovationThroughChange-div {
    min-height: 100vh;
    max-height: max-content;
    padding: 4.641vh 9.765vw !important;
    display: flex;
    position: static;
    flex-direction: column;
    justify-content: center;
  }
  .DrivingInnovationThroughChange-content {
    margin-top: calc(40px + 4.641vh);
  }
  .DrivingInnovationThroughChange-img {
    display: none;
  }
  .DrivingInnovationThroughChange-img-mobile {
    display: block;
    position: static;
    height: fit-content;
  }
  .DrivingInnovationThroughChange-img-mobile img {
    width: 100%;
    height: auto;
    transform: translateY(0);
  }
}
@media only screen and (min-width: 768px) and (orientation: landscape) {
  .DrivingInnovationThroughChange-div {
    min-height: 100vh;
    max-height: 100vw;
    padding: 4.641vw 9.765vh !important;
    display: flex;
    position: static;
    flex-direction: column;
    justify-content: center;
  }
  .DrivingInnovationThroughChange-content {
    margin-top: calc(40px + 4.641vw);
  }
  .DrivingInnovationThroughChange-img {
    display: none;
    display: block;
    position: static;
  }
  .DrivingInnovationThroughChange-img img {
    width: 100%;
    height: fit-content;
  }
  .DrivingInnovationThroughChange-img-mobile {
    display: none;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .DrivingInnovationThroughChange-img-mobile {
    display: none;
  }
  .DrivingInnovationThroughChange-div {
    display: block;
    min-height: 100vh;
    max-height: max-content;
    padding: 5.305vh 11.393vw !important;
    position: relative;
  }
  .DrivingInnovationThroughChange-content {
    margin-top: calc(30px + 5.305vh);
  }
  .DrivingInnovationThroughChange-img {
    display: block;
    position: absolute;
    right: 5.305vw;
    bottom: 5vh;
    margin-top: 0px;
  }
  .DrivingInnovationThroughChange-img img {
    height: 60vh;
    width: auto;
    transform: translateY(0);
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .DrivingInnovationThroughChange-img-mobile {
    display: none;
  }
  .DrivingInnovationThroughChange-div {
    min-height: 100vh;
    padding: 6.631vh 13.02vw !important;
  }
  .DrivingInnovationThroughChange-content {
    margin-top: calc(30px + 6.631vh);
  }
  .DrivingInnovationThroughChange-img {
    right: 6.631vw;
    bottom: 2vh;
  }
  .DrivingInnovationThroughChange-img img {
    height: 68vh;
    width: auto;
  }
}
