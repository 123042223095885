.LetsTalk-div {
  min-height: 40vh;
  width: 100% !important;
  padding: 6.631vh 13.02vw !important;
  background-color: #d6932c;
}

.LetsTalk-content {
  height: calc(100% - 6.631vh);
  transform: translateY(40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  color: #fdfdfd;
}
.LetsTalk-button {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  width: fit-content;
  color: #fdfdfd;
  border: 1px solid #3a3632;
  background-color: #3a3632 !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 12px 50px;
  letter-spacing: 1.5px;
  margin-top: 20px;
  font-weight: 700;
  font-size: 14px;
}

.LetsTalk-button:hover {
  background-color: transparent !important;
}

@media screen and (max-width: 767px) {
  .LetsTalk-button {
    background-color: transparent;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding: 12px 50px;
    margin-top: 20px;
    font-weight: 700;
    font-size: 14px;
  }
  .LetsTalk-button:hover {
    background-color: transparent !important;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .LetsTalk-div {
    min-height: 40vh;
    padding: 3.315vh 6.51vw !important;
  }
  .LetsTalk-content {
    height: calc(100% - 3.315vh);
    transform: translateY(40px);
  }
}
@media only screen and (max-width: 600px) and (orientation: landscape) {
  .LetsTalk-div {
    min-height: 40vw;
    padding: 3.315vw 6.51vh !important;
  }
  .LetsTalk-content {
    height: calc(100% - 3.315vw);
    transform: translateY(40px);
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .LetsTalk-div {
    min-height: 40vh;
    padding: 3.978vh 8.138vw !important;
  }
  .LetsTalk-content {
    height: calc(100% - 3.978vh);
    transform: translateY(40px);
  }
}
@media only screen and (min-width: 600px) and (orientation: landscape) {
  .LetsTalk-div {
    min-height: 40vw;
    padding: 3.978vw 8.138vh !important;
  }
  .LetsTalk-content {
    height: calc(100% - 3.978vw);
    transform: translateY(40px);
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .LetsTalk-div {
    min-height: 40vh;
    padding: 4.641vh 9.765vw !important;
  }
  .LetsTalk-content {
    height: calc(100% - 4.641vh);
    transform: translateY(40px);
  }
}
@media only screen and (min-width: 768px) and (orientation: landscape) {
  .LetsTalk-div {
    min-height: 40vw;
    padding: 4.641vw 9.765vh !important;
  }
  .LetsTalk-content {
    height: calc(100% - 4.641vw);
    transform: translateY(40px);
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .LetsTalk-div {
    min-height: max(40vh, 240px);
    padding: 5.305vh 11.393vw !important;
  }
  .LetsTalk-content {
    height: calc(100% - 5.305vh);
    transform: translateY(40px);
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .LetsTalk-div {
    padding: 6.631vh 12.02vw !important;
  }
  .LetsTalk-content {
    height: calc(100% - 6.631vh);
    transform: translateY(40px);
  }
}
