.scroll-to-top {
  position: fixed;
  height: 40px;
  width: 40px;
  bottom: 20px;
  right: 20px;
  display: none;
  z-index: 1;
  background-color: #f4f4f4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  box-shadow: 0 0 5px #3a3632 !important;
  color: #2c7fbf !important;
}
.scroll-to-top:hover,
.scroll-to-top:focus {
  box-shadow: 0 0 7.5px #3a3632 !important;
}
.scroll-to-top-hidden {
  display: none;
}
