.innovation-homescreen {
  min-height: 100vh;
  background-color: #2c7fbf;
  width: 100% !important;
  padding: 6.631vh 13.02vw !important;
}
.innovation-content-container {
  margin-top: calc(30px + 6.631vh);
  display: flex;
  height: calc(100% - 6.631vh - 6.631vh - 30px);
  width: 100%;
}
.innovation-home-img {
  width: 50%;
}
.innovation-home-img img {
  width: 90%;
  height: auto;
}
.innovation-home-content {
  width: 50%;
}

#innovation-home-img {
  order: 1 !important;
}
#innovation-home-content {
  order: 2 !important;
}

@media only screen and (max-width: 300px) {
  .innovation-homescreen .h2-heading {
    font-size: 24px !important;
  }
}
@media only screen and (max-width: 600px) {
  .innovation-homescreen {
    padding: 3.315vh 6.51vw !important;
  }
}
@media only screen and (max-width: 600px) and (orientation:landscape){
  .innovation-homescreen {
    padding: 3.315vh 6.51vw !important;
  }
}
@media only screen and (min-width: 600px) {
  .innovation-homescreen {
    padding: 3.978vh 8.138vw !important;
  }
}
@media only screen and (min-width: 600px) and (orientation:landscape){
  .innovation-homescreen {
    padding: 3.978vw 8.138vh !important;
  }
}
@media only screen and (min-width: 768px) {
  .innovation-homescreen {
    padding: 4.641vh 9.765vw !important;
  }
}
@media only screen and (min-width: 767px) and (orientation:landscape){
  .innovation-homescreen {
    padding: 4.641vw 9.765vh !important;
  }
}
@media only screen and (max-width: 992px) {
  .innovation-homescreen .h2-heading {
    font-size: 30px !important;
  }
  .innovation-content-container {
    gap: 10vh;

    flex-direction: column !important;
    justify-content: space-between;
    height: calc(100% - 6.631vh - 6.631vh - 30px) !important;
  }
  .innovation-home-img {
    width: 100% !important;
  }
  .innovation-home-img img {
    width: 100% !important;
    height:auto;
  }
  .innovation-home-content {
    width: 100% !important;
    text-align: center;
  }
  #innovation-home-img {
    order: 2 !important;
  }
  #innovation-home-content {
    order: 1 !important;
  }
}

@media only screen and (min-width: 992px) {
  .innovation-homescreen{
    padding: 5.305vh 11.393vw !important;
  }
  .innovation-home-img img {
    width: 90%;
    height: 100%;
  }
  .innovation-home-img {
    width: 60%;
  }
  .innovation-home-content {
    width: 40%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .innovation-homescreen {
    min-height: 100vh;
    background-color: #2c7fbf;
    width: 100%;
    padding: 6.631vh 13.02vw !important;
  }
  .innovation-content-container {
    margin-top: calc(30px + 6.631vh);
    display: flex;
    width: 100%;
  }
  .innovation-home-img {
    width: 50%;
  }
  .innovation-home-img img {
    width: 90%;
  }
  .innovation-home-content {
    width: 50%;
  }

  #innovation-home-img {
    order: 1 !important;
  }
  #innovation-home-content {
    order: 2 !important;
  }
}
