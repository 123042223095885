* {
  box-sizing: border-box !important;
}
/* html, */
/* body { */
/* overflow-x: clip !important;
    overflow-y: unset !important; */
/* } */
body {
  font-family: "Inter", sans-serif !important;
}
a {
  text-decoration: none !important;
}
.logo {
  width: 200px;
  position: fixed;
  top: 10%;
  left: 10%;
  z-index: 1;
}
.text-black {
  color: #3a3632 !important;
}
.h2-heading {
  font-size: 50px;
  font-weight: 500;
  color: #fdfdfd;
}
.h4-subheading {
  font-size: 20px;
  line-height: 23.5px;
  font-weight: 400;
  color: #fdfdfd;
  font-style: italic;
  margin-top: 5px !important;
}
.paragraph-text {
  font-size: 20px;
  font-weight: 400;
}
.paragraph-small-text {
  font-size: 16px;
  font-weight: 400;
  color: #3a3632 !important;
}
.justify-text {
  text-align: justify;
  text-justify: inter-character !important;
}

.text-white {
  color: #fdfdfd !important;
}
.weight-800 {
  font-weight: 800 !important;
}

.h2 {
  font-size: 32px !important;
  font-weight: 600 !important;
}
.h3 {
  font-size: 24px !important;
  font-weight: 500 !important;
}
.h4 {
  font-weight: 600 !important;
  font-size: 1.2rem;
}
.p {
  font-size: 16px !important;
  font-weight: 400 !important;
  text-align: justify !important;
  text-justify: inter-word !important;
}
.p-bold {
  font-size: 18px !important;
  font-weight: 600 !important;
  margin-top: 20px !important;
}
.mrz-pz {
  margin: 0 !important;
  padding: 0 !important;
}
.center-text {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .h2-heading {
    font-size: 36px;
  }
  .h4-subheading {
    font-size: 20px;
    line-height: 23.5px;
    font-weight: 400;
  }
  .h2 {
    font-size: 24px !important;
  }
  .h3 {
    font-size: 16px !important;
  }
  .p {
    font-size: 14px !important;
  }
  .p-bold {
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 600px) {
  .h4-subheading {
    font-size: 18px;
    line-height: 23.5px;
    font-weight: 400;
  }
}
.ant-btn:active,
.ant-btn:focus,
.ant-btn:hover {
  border: none;
  outline: none;
}

/*Custom Animations*/
/* .fadeInLeft {
  animation-delay: 0.3s;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInLeft;
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.fadeInRight {
  animation-delay: 0.3s;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInRight;
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
} */
