.homepage-heading {
  font-size: 50px;
  font-weight: 500;
  color: #fdfdfd;
}
@media only screen and (max-width: 767px) {
    .homepage-heading {
      font-size: 30px;
      line-height: 1.1;
    }
}