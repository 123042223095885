.footer-main-div {
  margin: 0;
  min-height: 60vh;
  width: 100% !important;
  background-color: #fdfdfd;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-header {
  background-color: #2c7fbf;
  width: 100% !important;
  height: 10px;
}

.footer-body {
  padding: 6.631vh 13.02vw;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100% !important;
}

.footer-body-container {
  width: 100% !important;
  height: 100% !important;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

.footer-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.footer-section-connect {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: fit-content;
}

.footer-main-div p {
  text-align: center;
  color: #3a3632;
  font-size: 16px !important;
  font-weight: 300 !important;
}

.footer-main-div h3 {
  color: #3a3632;
  font-size: 22px !important;
  font-weight: 900 !important;
}

.footer-main-div a {
  color: #3a3632;
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-top: 4px;
  margin-bottom: 4px;
}

.footer-main-div a:hover {
  color: #2c7fbf;
}

.footer-lejhro-content {
  padding: 30px 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 20px;
  text-align: left !important;
  align-items: flex-start;
}
.footer-lejhro-content p {
  text-align: left !important;
}
@media screen and (max-width: 767px) {
  .footer-main-div h3 {
    color: #3a3632;
    font-size: 22px !important;
    font-weight: 900 !important;
  }
  .footer-main-div p {
    text-align: center;
    color: #3a3632;
    font-size: 14px !important;
    font-weight: 300 !important;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .footer-main-div {
    min-height: fit-content;
  }
  .footer-body {
    height: fit-content !important;
    padding: 3.315vh 6.51vw;
  }
  .footer-section {
    width: 100% !important;
  }
  .footer-body-container {
    grid-template-columns: 1fr !important;
    height: fit-content !important;
  }
}
@media only screen and (max-width: 600px) and (orientation:landscape) {
  .footer-main-div {
    min-height: fit-content;
  }
  .footer-body {
    height: fit-content !important;
    padding: 3.315vw 6.51vh;
  }
  .footer-section {
    width: 100% !important;
  }
  .footer-body-container {
    grid-template-columns: 1fr !important;
    height: fit-content !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .footer-main-div {
    min-height: fit-content;
  }
  .footer-section {
    width: 100% !important;
  }
  .footer-body-container {
    grid-template-columns: 1fr 1fr !important;
  }
  .footer-body {
    padding: 3.978vh 8.138vw !important;
  }
}
@media only screen and (min-width: 600px) and (orientation:landscape) {
  .footer-main-div {
    min-height: fit-content;
  }
  .footer-section {
    width: 100% !important;
  }
  .footer-body-container {
    grid-template-columns: 1fr 1fr !important;
  }
  .footer-body {
    padding: 3.978vw 8.138vh !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .footer-main-div {
    min-height: 60vh;
  }
  .footer-body-container {
    grid-template-columns: 1fr 1fr !important;
  }
  .footer-body {
    padding: 4.641vh 9.765vw;
  }
}
@media only screen and (min-width: 768px) and (orientation: landscape){
  .footer-main-div {
    min-height: 60vh;
  }
  .footer-body-container {
    grid-template-columns: 1fr 1fr !important;
  }
  .footer-body {
    padding: 4.641vw 9.765vh;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .footer-main-div {
    min-height: 60vh;
  }
  .footer-body-container {
    grid-template-columns: 1fr 1fr 1fr !important;
  }
  .footer-body {
    padding: 5.305vh 11.393vw !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .footer-main-div {
    min-height: 60vh;
  }

  .footer-body-container {
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
  }
  .footer-body {
    padding: 6.631vh 13.02vw !important;
  }
}
