.btn :hover {
  transition: all 0.3 ease-in-out;
  background-color: #fdfdfd !important;
  border: 1px solid #b59370 !important;
  color: #3a3632 !important;
  /* border: none !important;/ */
}

.btt {
  border: 1px solid #b59370;
  background-color: #d6932c;
  color: white;
}

/* .btt :hover {
  color: #d6932c !important;
  background-color: white;
} */
.hover-button:hover {
  background-color: #fdfdfd !important;
  color: #3a3632 !important;
  border: 1px solid #b59370 !important;
  transition: all 0.3s ease-in-out;
}

.btt:focus {
  background-color: #fdfdfd;
  color: #fdfdfd;
  border: 1px solid #b59370;
  background-color: #d6932c;

}


@media only screen and (max-width: 320px) {
  .about {
    /* background-size: 110% auto !important; */
    

    /* Down-center the image */

    /* background-position: center 100%; */

    padding-top: 30px !important;

    padding-bottom: 100px !important;
  }

  .about-content {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }

  .bg {
    width: 100% !important;
    /* padding-bottom:100px !important; */
    margin-top: -116px !important;

  }
  .pic{
    width:100% !important;
    height:100% !important;
  }
}



@media only screen and (min-width: 321px) and (max-width: 480px) {
  .about {
    /* background-size: 110% auto !important; */

    /* Down-center the image */

    /* background-position: center 100%; */

    padding-top: 30px !important;

    padding-bottom: 100px !important;


  }

  .about-content {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }

  .bg {
    width: 100% !important;
    /* padding-bottom:100px !important; */
    margin-top: -116px !important;

  }
  .pic{
    width:100% !important;
    height:100% !important;
  }

}

@media only screen and (min-width: 481px) and (max-width: 600px) {
  .about-content {
    padding-bottom: 1rem !important;
    padding-top: 2rem !important;
  }

  .about {
    /* background-size: 110% auto !important; */

    /* Down-center the image */

    /* background-position: center 100%; */

    padding-top: 30px !important;

    padding-bottom: 130px !important;
  }

  .bg {
    width: 100% !important;
    /* padding-bottom:100px !important; */
    margin-top: -145px !important;

  }
  .pic{
    width:100% !important;
    height:100% !important;
  }

}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .about {
    /* background-size: 110% auto !important; */

    /* Down-center the image */

    /* background-position: center 100%; */

    padding-top: 30px !important;

    padding-bottom: 110px !important;
    padding-left: 22px;
  }

  .bg {
    width: 100% !important;
    /* padding-bottom:100px !important; */
    margin-top: -153px !important;

  }
  .pic{
    width:100% !important;
    height:100% !important;
  }

  .about-content p {
    font-size: 24px !important;
  }

}

@media only screen and (min-width: 769px) and (max-width: 992px) {
  .about {
    /* background-size: 110% auto !important; */

    /* Down-center the image */

    /* background-position: center 100%; */

    padding-top: 30px !important;

    padding-bottom: 130px !important;
    padding-left: 40px;
  }

  .about-content p {
    font-size: 22px !important;
  }

  .bg {
    width: 100% !important;
    /* padding-bottom:100px !important; */
    margin-top: -175px !important;

  }
  .pic{
    width:100% !important;
    height:100% !important;
  }


}

@media only screen and (min-width: 993px)and (max-width:1050px) {
  .about {
    /* background-size: 100% auto !important; */
    margin-left: 90px;

  }

  .bg {
    width: 43% !important;
    /* padding-bottom:100px !important; */
    /* margin-top: -50px !important; */

  }
  .pic{
    width:100% !important;
    height:100% !important;
  }
}

@media only screen and (min-width: 1050px) and (max-width:1121px) {

  .about {
    /* background-size: 100% auto !important; */
    margin-left: 100px;

  }

  .bg {
    width: 45% !important;
    /* padding-bottom:100px !important; */
    /* margin-top: -40px !important; */

  }
  .pic{
    width:100% !important;
    height:100% !important;
  }
}

@media only screen and (min-width: 1121px) and (max-width:1200px) {

  .about {
    /* background-size: 100% auto !important; */
    margin-left: 100px;

  }

  .bg {
    width: 50% !important;
    /* padding-bottom:100px !important; */
    /* margin-top: -40px !important; */

  }
  .pic{
    width:100% !important;
    height:100% !important;
  }
}

@media only screen and (min-width:1200px)and (max-width:1300px) {
  .about {
    margin-left: 130px;
  }
  .bg {
    width: 50% !important;
    /* display:flex !important; */
    /* padding-bottom:100px !important; */
    /* margin-top: -40px !important; */

  }
  .pic{
    width:100% !important;
    height:100% !important;
  }
}

@media only screen and (min-width:1300px)and (max-width:1350px){
  .about{
    margin-left:100px;
  }
  .bg{
    width:50% !important;
  }
  .pic{
    width:100% !important;
    height:100% !important;
  }
}

@media only screen and (min-width:1350px){
  .about{
    margin-left:35px;
  }
  .pic{
    width:100% !important;
    height:100% !important;
  }
  .bg{
    width:59% !important;
    
  }
}











.request_text {

  text-align: center;

  font-weight: 600;
  font-size: 20px;
  color: #3a3632
}