.innovation-product-development {
  width: 100% !important;
  padding: 6.631vh 13.02vw !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fdfdfd;
}
.product-development-content-1-img {
  display: flex;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-development-content-1-img img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .innovation-product-development {
    padding: 3.315vh 6.51vw !important;
    gap: 20px !important;
  }
}
@media only screen and (max-width: 600px) and (orientation:landscape) {
  .innovation-product-development {
    padding: 3.315vw 6.51vh !important;
    gap: 20px !important;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .innovation-product-development {
    padding: 3.978vh 8.138vw !important;
  }
}
@media only screen and (min-width: 600px) and (orientation:landscape){
  .innovation-product-development {
    padding: 3.978vw 8.138vh !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .innovation-product-development {
    padding: 4.641vh 9.765vw !important;
  }
}
@media only screen and (min-width: 768px) and (orientation:landscape){
  .innovation-product-development {
    padding: 4.641vw 9.765vh !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .innovation-product-development {
    padding: 5.305vh 11.393vw !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .innovation-product-development {
    padding: 6.631vh 13.02vw !important;
    justify-content: space-between !important;
  }
}
