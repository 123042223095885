.innovation-r-d {
  width: 100% !important;
  padding: 6.631vh 13.02vw !important;
  background-color: #f4f4f4;
}
.innovation-r-d-content {
  display: flex;
  justify-content: space-between;
}
.innovation-r-d-content-left,
.innovation-r-d-content-right {
  width: 50%;
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
  min-height: 100%;
  max-height: fit-content;
}
.innovation-r-d-content-left {
  width: 40% !important;
  display: flex;
  flex-direction: column;
  align-items: space-around;
}
.innovation-r-d-content-right img {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .innovation-r-d {
    padding: 3.315vh 6.51vw !important;
  }
  .innovation-r-d-content {
    flex-direction: column;
    gap: 20px;
  }
  .innovation-r-d-content-right {
    width: 100%;
  }
  .innovation-r-d-content-left {
    width: 100% !important;
  }
}
@media only screen and (max-width: 600px) and (orientation:landscape){
  .innovation-r-d {
    padding: 3.315vw 6.51vh !important;
  }
  .innovation-r-d-content {
    flex-direction: column;
    gap: 20px;
  }
  .innovation-r-d-content-right {
    width: 100%;
  }
  .innovation-r-d-content-left {
    width: 100% !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .innovation-r-d {
    padding: 3.978vh 8.138vw !important;
  }
  .innovation-r-d-content {
    flex-direction: column;
    gap: 20px;
  }
  .innovation-r-d-content-right {
    width: 100%;
  }
  .innovation-r-d-content-left {
    width: 100% !important;
  }
}
@media only screen and (min-width: 600px) and (orientation:landscape){
  .innovation-r-d {
    padding: 3.978vw 8.138vh !important;
  }
  .innovation-r-d-content {
    flex-direction: column;
    gap: 20px;
  }
  .innovation-r-d-content-right {
    width: 100%;
  }
  .innovation-r-d-content-left {
    width: 100% !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .innovation-r-d {
    padding: 4.641vh 9.765vw !important;
  }
  .innovation-r-d-content {
    flex-direction: column;
    gap: 20px;
  }
  .innovation-r-d-content-right {
    width: 100%;
  }
  .innovation-r-d-content-left {
    width: 100% !important;
  }
}
@media only screen and (min-width: 768px) {
  .innovation-r-d {
    padding: 4.641vw 9.765vh !important;
  }
  .innovation-r-d-content {
    flex-direction: column;
    gap: 20px;
  }
  .innovation-r-d-content-right {
    width: 100%;
  }
  .innovation-r-d-content-left {
    width: 100% !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .innovation-r-d {
    padding: 5.305vh 11.393vw !important;
  }
  .innovation-r-d-content {
    flex-direction: row !important;
  }
  .innovation-r-d-content-right {
    width: 50%;
  }
  .innovation-r-d-content-left {
    width: 40% !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .innovation-r-d {
    padding: 6.631vh 13.02vw !important;
  }
  .innovation-r-d-content {
    flex-direction: row !important;
  }
  .innovation-r-d-content-right {
    width: 50%;
  }
  .innovation-r-d-content-left {
    width: 40% !important;
  }
}
