.contact-form {
  background-color: #fdfdfd;
}
.ant-spin-nested-loading {
  width: 100% !important;
}
.ant-spin-dot-item {
  color: #3a3632 !important;
  background-color: #3a3632 !important;
}

.contact-form .ant-btn {
  background-color: #d6932c !important;
}
.contact-form .ant-btn-primary {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 50px;
  color: #fdfdfd;
  border: 1px solid #d6932c;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: large;
}
.contact-form .ant-btn-primary:hover {
  transition: all 0.3 ease-in-out;
  background-color: #fdfdfd !important;
  border: 1px solid #3a3632;
  color: #3a3632;
}

.contact-form-field-holder {
  position: relative;
  margin-bottom: 1.25em;
  margin-top: 22px;
  background-color: #fdfdfd;
}
.contact-form-field-holder span {
  color: #dd3b30;
  font-size: larger;
  transform: translate(3px, -3px) !important;
}
input {
  border: 0;
  border-bottom: 2px solid #3a3632;
  outline: none;
  background-color: #fdfdfd;
}
label {
  position: absolute;
  top: 0;
  left: 0;
  color: #3a363290;
  display: flex;
  align-items: center;
  cursor: text;
  transition: all 0.3s ease-out;
}

input,
label {
  width: 100%;
  height: 55px;
}
input:focus + label {
  top: -30px;
  pointer-events: none;
}
.contact-form-field-label-text {
  top: -30px;
  pointer-events: none;
}

input:focus {
  border-bottom: 3.5px solid #2c7fbf;
  transition: all 0.3s ease-out;
}
.contact-form-field-holder-error input {
  border-bottom: 3.5px solid #dd3b30 !important;
  transition: all 0.3s ease-out;
}
.contact-form-field-holder-error input:focus {
  border-bottom: 3.5px solid #2c7fbf !important;
  transition: all 0.3s ease-out;
}
.hidden {
  display: none;
}
.contact-form-error-p {
  display: block;
  margin: 5px 10px;
  color: #dd3b30;
  font-size: 12px;
}

.contact-form-button-holder {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .contact-form .ant-btn-primary {
    padding: 20px 40px;
  }
}
