.services-data-analyst-content{
  width: 100% !important;
  padding: 6.631vh  13.02vw !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f4f4;
}
.services-data-analyst-content-left{
  width: 40%;
}
.services-data-analyst-content-right{
  width: 50%;
}

#services-data-analyst-content-left{order: 1;}
#services-data-analyst-content-right{order: 2;}


.services-data-analyst-content-right img{
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .services-data-analyst-content{
    padding: 3.315vh  6.510vw !important;
    flex-direction: column;
    gap:20px;
  }
  .services-data-analyst-content-right{
    width: 90%;
  }
  .services-data-analyst-content-left{
    width: 100%;
  }
  #services-data-analyst-content-left{order: 2 !important;}
  #services-data-analyst-content-right{order: 1 !important;}
}
@media only screen and (max-width: 600px) and (orientation:landscape){
  .services-data-analyst-content{
    padding: 3.315vw  6.510vh !important;
    flex-direction: column;
    gap:20px;
  }
  .services-data-analyst-content-right{
    width: 90%;
  }
  .services-data-analyst-content-left{
    width: 100%;
  }
  #services-data-analyst-content-left{order: 2 !important;}
  #services-data-analyst-content-right{order: 1 !important;}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .services-data-analyst-content{
    padding: 3.978vh  8.138vw !important;
    flex-direction: column;
    gap:20px;
  }
  .services-data-analyst-content-right{
    width: 90%;
  }
  .services-data-analyst-content-left{
    width: 100%;
  }
  #services-data-analyst-content-left{order: 2 !important;}
  #services-data-analyst-content-right{order: 1 !important;}
}
@media only screen and (min-width: 600px) and (orientation:landscape){
  .services-data-analyst-content{
    padding: 3.978vw  8.138vh !important;
    flex-direction: row !important;
  }
  .services-data-analyst-content-right{
    width: 50%;
  }
  .services-data-analyst-content-left{
    width: 50%;
  }
  #services-data-analyst-content-left{order: 1 !important;}
  #services-data-analyst-content-right{order: 2 !important;}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .services-data-analyst-content{
    padding: 4.641vh  9.765vw !important;
    flex-direction: column;
    gap:20px;
  }
  .services-data-analyst-content-right{
    width: 90%;
  }
  .services-data-analyst-content-left{
    width: 100%;
  }
  #services-data-analyst-content-left{order: 2 !important;}
  #services-data-analyst-content-right{order: 1 !important;}
}
@media only screen and (min-width: 768px) and (orientation:landscape){
  .services-data-analyst-content{
    padding: 4.641vw  9.765vh !important;
    flex-direction: row !important;
  }
  .services-data-analyst-content-right{
    width: 45%;
  }
  .services-data-analyst-content-left{
    width: 50%;
  }
  #services-data-analyst-content-left{order: 1 !important;}
  #services-data-analyst-content-right{order: 2 !important;}
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .services-data-analyst-content{
    padding: 5.305vh  11.393vw !important;
    flex-direction: row !important;
  }
  .services-data-analyst-content-right{
    width: 50%;
  }
  .services-data-analyst-content-left{
    width: 40%;
  }
  #services-data-analyst-content-left{order: 1 !important;}
  #services-data-analyst-content-right{order: 2 !important;}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .services-data-analyst-content{
    padding: 6.631vh  13.02vw !important;
    flex-direction: row !important;
  }
  .services-data-analyst-content-right{
    width: 50%;
  }
  .services-data-analyst-content-left{
    width: 40%;
  }
  #services-data-analyst-content-left{order: 1 !important;}
  #services-data-analyst-content-right{order: 2 !important;}

}

.h2-1{
  display: none;
}
@media only screen and (max-width: 600px){
  .h2-1{
    display: block;
  }
  .h2-2{
    display: none;
  }
}
@media only screen and (min-width: 600px){
  .h2-1{
    display: block;
  }
  .h2-2{
    display: none;
  }
}
@media only screen and (min-width: 600px) and (orientation:landscape){
  .h2-1{
    display: none;
  }
  .h2-2{
    display: block;
  }
}
@media only screen and (min-width: 768px) {
  .h2-1{
    display: block;
  }
  .h2-2{
    display: none;
  }
}
@media only screen and (min-width: 768px) and (orientation: landscape){
  .h2-1{
    display: none;
  }
  .h2-2{
    display: block;
  }
}
@media only screen and (min-width: 992px){
  .h2-1{
    display: none;
  }
  .h2-2{
    display: block;
  }
}