.privacy-statement-home {
  height: 40vh;
  background-color: #d6932c;
  width: 100% !important;
  padding: 6.631vh 13.02vw !important;
}

.privacy-statement-name {
  display: flex;
  align-items: flex-end;
  height: calc(40vh - 6.631vh - 30px);
  padding: 6.631vh 0;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .privacy-statement-home {
    padding: 3.315vh 6.51vw !important;
    height: 25vh !important;
  }
  .privacy-statement-name {
    display: flex !important;
    align-items: flex-end !important;
    height: calc(25vh - 3.315vh - 20px) !important;
    padding: 3.315vh 0 !important;
  }
  .privacy-statement-home .h2-heading{
    font-size: 30px !important;
    line-height: 1.1;
  }
}
@media only screen and (max-width: 600px) and (orientation: landscape) {
  .privacy-statement-home {
    padding: 3.315vw 6.51vh !important;
    height: 25vw !important;
  }
  .privacy-statement-name {
    display: flex !important;
    align-items: flex-end !important;
    height: calc(25vw - 3.315vw - 20px) !important;
    padding: 3.315vw 0 !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .privacy-statement-home {
    padding: 3.978vh 8.138vw !important;
    height: 30vh !important;
  }
  .privacy-statement-name {
    display: flex;
    align-items: flex-end;
    height: calc(30vh - 3.978vh - 20px);
    padding: 3.978vh 0;
  }
}
@media only screen and (min-width: 600px) and (orientation: landscape) {
  .privacy-statement-home {
    padding: 3.315vw 6.51vh !important;
    height: 25vw !important;
  }
  .privacy-statement-name {
    display: flex !important;
    align-items: flex-end !important;
    height: calc(25vw - 3.315vw - 20px) !important;
    padding: 3.315vw 0 !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .privacy-statement-home {
    padding: 4.641vh 9.765vw !important;
    height: 30vh;
  }
  .privacy-statement-name {
    display: flex;
    align-items: flex-end;
    height: calc(30vh - 4.641vh - 30px);
    padding: 4.641vh 0;
  }
}
@media only screen and (min-width: 768px) and (orientation: landscape) {
  .privacy-statement-home {
    padding: 4.641vw 9.765vh !important;
    height: 30vw;
  }
  .privacy-statement-name {
    display: flex;
    align-items: flex-end;
    height: calc(30vw - 4.641vw - 30px);
    padding: 4.641vh 0;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .privacy-statement-home {
    padding: 5.305vh 11.393vw !important;
    height: 30vh !important;
  }
  .privacy-statement-name {
    display: flex !important;
    align-items: flex-end;
    height: calc(40vh - 5.305vh - 30px - 5.305vh) !important;
    padding: 5.305vh 0 !important;
  }
}
@media only screen and (min-width: 992px) and (orientation: landscape) {
  .privacy-statement-home {
    padding: 5.305vh 11.393vw !important;
    height: 30vh !important;
  }
  .privacy-statement-name {
    display: flex !important;
    align-items: flex-end;
    height: calc(40vh - 5.305vh - 30px - 5.305vh) !important;
    padding: 5.305vh 0 !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .privacy-statement-home {
    padding: 6.631vh 13.02vw !important;
    height: max(40vh, 150px) !important;
  }
  .privacy-statement-name {
    color: #fdfdfd !important;
    display: flex !important;
    align-items: flex-end !important;
    padding: 6.631vh 0 !important;
    height: max(calc(40vh - 6.631vh - 30px), calc(120px - 6.631vh)) !important;
  }
}
