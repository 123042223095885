.top-fixed-header {
  background-color: #fdfdfd;
  position: fixed !important;
  width: 100vw !important;
  top: 0 !important;
  left: 0 !important;
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between !important;
  padding: 0 13.02vw;
  z-index: 4000;
  box-shadow: 0 0 5px #3a3632;
}
.top-fixed-header-fixed-logo {
  height: 30px;
}
.top-fixed-header-drawer-button {
  display: flex !important;
  height: 40px !important;
  width: 40px !important;
  border-radius: 100% !important;
  background-color: #3a3632 !important;
  color: #fdfdfd !important;
  margin: 0 !important;
  padding: 4px !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: flex-end !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  background-color: #3a3632 !important;
}
.top-fixed-header-drawer-button div {
  background: #fdfdfd;
  height: 2px;
  width: 100%;
  margin: 3px 0;
}
.top-fixed-header-drawer-button .side-drawer-button-container-div-line2 {
  width: 60%;
}
.top-fixed-header-drawer-button:hover,
.top-header-drawer-button:focus {
  background-color: #3a3632 !important;
  color: #fdfdfd !important;
  border: 1px solid #3a3632 !important;
}
.top-fixed-header-container {
  display: flex;
  justify-content: space-around;
  gap: 50px;
  align-items: center;
}
.top-fixed-header-drawer-button {
  height: 40px !important;
  width: 40px !important;
}
.top-fixed-header-contact-div a {
  font-weight: 700;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color:#2c7fbf;
}
.top-fixed-header-hidden {
  display: none;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .top-fixed-header-fixed-logo {
    height: 20px;
  }
  .top-fixed-header {
    padding: 0 6.51vw !important;
  }
  .top-fixed-header-container {
    gap: 10px !important;
  }
  .top-fixed-header-contact-div {
    display: none;
  }
}
@media only screen and (max-width: 600px) and (orientation: landscape) {
  .top-fixed-header-fixed-logo {
    height: 25px !important;
  }
  .top-fixed-header {
    padding: 0 6.51vh !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .top-fixed-header {
    padding: 0 8.138vw !important;
  }
  .top-fixed-header-container {
    gap: 20px !important;
  }
}
@media only screen and (min-width: 600px) and (orientation: landscape){
  .top-fixed-header {
    padding: 0 8.138vh !important;
  }
  .top-fixed-header-container {
    gap: 20px !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .top-fixed-header {
    padding: 0 9.765vw !important;
  }
  .top-fixed-header-container {
    gap: 20px !important;
  }
}
@media only screen and (min-width: 768px) and (orientation: landscape){
  .top-fixed-header {
    padding: 0 9.765vh !important;
  }
  .top-fixed-header-container {
    gap: 20px !important;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .top-fixed-header {
    padding: 0 11.393vw !important;
  }
  .top-fixed-header-container {
    gap: 40px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .top-fixed-header {
    padding: 0 13.02vw !important;
    width: 100vw !important;
  }
  .top-fixed-header-container {
    gap: 50px !important;
  }
}
