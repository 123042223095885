.contact-details-page {
  padding: 6.631vh 13.02vw;
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  background-color: #fdfdfd;
}
.contact-hubsopt {
  width: 50%;
}
.contact-details {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  padding: 16px 0;
}
.contact-details h4 {
  color: #3a3632;
  font-size: 20px;
  font-weight: 800;
}
.contact-details p {
  color: #3a3632;
  font-size: 16px;
  font-weight: 400;
}
.contact-details-inside-container {
  display: flex;
  gap: 10px;
}

@media only screen and (max-width: 600px) {
  .contact-details-page {
    padding: 3.315vh 6.51vw !important;
    gap: 50px;
    flex-direction: column !important;
  }
  .contact-hubsopt,
  .contact-details {
    width: 100% !important;
  }
  .contact-details {
    padding: 20px 0 !important;
  }
}
@media only screen and (max-width: 600px) and (orientation:landscape) {
  .contact-details-page {
    padding: 3.315vw 6.51vh !important;
    gap: 50px;
    flex-direction: column !important;
  }
  .contact-hubsopt,
  .contact-details {
    width: 100% !important;
  }
  .contact-details {
    padding: 20px 0 !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .contact-details-page {
    padding: 3.978vh 8.138vw !important;
    flex-direction: column !important;
    gap: 50px;
  }
  .contact-hubsopt,
  .contact-details {
    width: 100% !important;
  }
  .contact-details {
    padding: 20px 0 !important;
  }
}
@media only screen and (min-width: 600px) and (orientation:landscape){
  .contact-details-page {
    padding: 3.978vw 8.138vh !important;
    flex-direction: column !important;
    gap: 50px;
  }
  .contact-hubsopt,
  .contact-details {
    width: 100% !important;
  }
  .contact-details {
    padding: 20px 0 !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .contact-details-page {
    padding: 4.641vh 9.765vw !important;
    flex-direction: row !important;
    gap: 15px;
  }
  .contact-hubsopt,
  .contact-details {
    width: 50% !important;
  }
  .contact-details {
    padding: 16px 20px !important;
    padding: 16px 6.51vw !important;
  }
}
@media only screen and (min-width: 768px) and (orientation:landscape){
  .contact-details-page {
    padding: 4.641vw 9.765vh !important;
    flex-direction: row !important;
    gap: 15px;
  }
  .contact-hubsopt,
  .contact-details {
    width: 50% !important;
  }
  .contact-details {
    padding: 16px 20px !important;
    padding: 16px 6.51vw !important;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .contact-details-page {
    padding: 5.305vh 11.393vw !important;
    flex-direction: row !important;
  }
  .contact-hubsopt,
  .contact-details {
    width: 50% !important;
  }
  .contact-details {
    padding: 16px 100px !important;
    padding: 16px 6.51vw !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .contact-details-page {
    padding: 6.631vh 13.02vw !important;
    flex-direction: row !important;
  }
  .contact-hubsopt,
  .contact-details {
    width: 50% !important;
  }
  .contact-details {
    padding: 16px 100px !important;
    padding: 16px 6.51vw !important;
  }
}
