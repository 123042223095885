.innovation-product-development-content {
  width: 100% !important;
  padding: 6.631vh 13.02vw !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f4f4;
}

.innovation-product-development-content-left {
  width: 40%;
  
}
.innovation-product-development-content-right {
  width: 50%;
}
.ipdc-heading {
  font-weight: 800;
}

#innovation-product-development-content-left {
  order: 1;
}
#innovation-product-development-content-right {
  order: 2;
}

.ipdc-hidden-heading {
  display: none;
}

.innovation-product-development-content-right img {
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .innovation-product-development-content {
    padding: 3.315vh 6.51vw !important;
    flex-direction: column;
  }
  .innovation-product-development-content-right {
    width: 90%;
    margin-bottom: 10px;
  }
  .innovation-product-development-content-left {
    width: 100%;
  }
  #innovation-product-development-content-left {
    order: 2 !important;
  }
  #innovation-product-development-content-right {
    order: 1 !important;
  }
  .ipdc-hidden-heading {
    display: block;
  }
  .ipdc-heading {
    display: none;
  }
}
@media only screen and (max-width: 600px) and (orientation:landscape) {
  .innovation-product-development-content {
    padding: 3.315vw 6.51vh !important;
    flex-direction: column;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .innovation-product-development-content {
    padding: 3.978vh 8.138vw !important;
    flex-direction: column;
  }
  .innovation-product-development-content-right {
    width: 90%;
    margin-bottom: 10px;
  }
  .innovation-product-development-content-left {
    width: 100%;
  }
  #innovation-product-development-content-left {
    order: 2 !important;
  }
  #innovation-product-development-content-right {
    order: 1 !important;
  }
  .ipdc-hidden-heading {
    display: block;
  }
  .ipdc-heading {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (orientation:landscape){
  .innovation-product-development-content {
    padding: 3.978vw 8.138vh !important;
    flex-direction: column;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .innovation-product-development-content {
    padding: 4.641vh 9.765vw !important;
    flex-direction: column;
  }
  .innovation-product-development-content-right {
    width: 90%;
    margin-bottom: 10px;
  }
  .innovation-product-development-content-left {
    width: 100%;
  }
  #innovation-product-development-content-left {
    order: 2 !important;
  }
  #innovation-product-development-content-right {
    order: 1 !important;
  }
  .ipdc-hidden-heading {
    display: block;
  }
  .ipdc-heading {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (orientation:landscape){
  .innovation-product-development-content {
    padding: 4.641vw 9.765vh !important;
    flex-direction: column;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .innovation-product-development-content {
    padding: 5.305vh 11.393vw !important;
    flex-direction: row !important;
  }
  .innovation-product-development-content-right {
    width: 50%;
    margin-bottom: 0px;
  }
  .innovation-product-development-content-left {
    width: 40%;
  }
  #innovation-product-development-content-left {
    order: 1 !important;
  }
  #innovation-product-development-content-right {
    order: 2 !important;
  }
  .ipdc-hidden-heading {
    display: none;
  }
  .ipdc-heading {
    display: block;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .innovation-product-development-content {
    padding: 6.631vh 13.02vw !important;
    flex-direction: row !important;
  }
  .innovation-product-development-content-right {
    width: 50%;
    margin-bottom: 0px;
  }
  .innovation-product-development-content-left {
    width: 40%;
  }
  #innovation-product-development-content-left {
    order: 1 !important;
  }
  #innovation-product-development-content-right {
    order: 2 !important;
  }
  .ipdc-hidden-heading {
    display: none;
  }
  .ipdc-heading {
    display: block;
  }
}
