.about-intro {
  width: 100% !important;
  padding: 6.631vh 13.02vw !important;
  padding-top: 6.631 !important;
  position: relative;
  background-color: #f4f4f4;
}
.about-intro-content {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.about-intro-content-inside {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.about-intro-content-inside img {
  width: 50%;
  height: auto;
  border-radius: 50%;
  margin-bottom: 20px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .about-intro {
    padding: 3.315vh 6.51vw !important;
    padding-top: 3.315vh !important;
  }
  .about-intro-content {
    width: 100%;
    flex-direction: column;
    gap: 30px;
  }
  .about-intro-content-inside img {
    width: 70%;
    height: auto;
  }
}
@media only screen and (max-width: 600px) and (orientation:landscape){
  .about-intro {
    padding: 3.315vw 6.51vh !important;
    padding-top: 3.315vw !important;
  }
  .about-intro-content {
    width: 100%;
    flex-direction: column;
    gap: 30px;
  }
  .about-intro-content-inside img {
    width: 40%;
    height: auto;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .about-intro {
    padding: 3.978vh 8.138vw !important;
    padding-top: 3.978vh !important;
  }
  .about-intro-content {
    flex-direction: column;
    gap: 30px;
  }
  .about-intro-content-inside img {
    width: 70%;
  }
}
@media only screen and (min-width: 600px) and (orientation: landscape) {
  .about-intro {
    padding: 3.978vw 8.138vh !important;
    padding-top: 3.978vw !important;
  }
  .about-intro-content {
    flex-direction: row;
  }
  .about-intro-content-inside img {
    width: 50%;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .about-intro {
    padding: 4.641vh 9.765vw !important;
    padding-top: 4.641vh !important;
  }
  .about-intro-content {
    flex-direction: row;
  }
  .about-intro-content-inside img {
    width: 50%;
  }
}
@media only screen and (min-width: 768px) and (orientation: landscape){
  .about-intro {
    padding: 4.641vw 9.765vh !important;
    padding-top: 4.641vw !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .about-intro {
    padding: 5.305vh 11.393vw !important;
    padding-top: 5.305vh !important;
  }
  .about-intro-content {
    flex-direction: row;
  }
  .about-intro-content-inside img {
    width: 50%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .about-intro {
    padding: 6.631vh 13.02vw !important;
    padding-top: 6.631vh !important;
  }
  .about-intro-content {
    flex-direction: row;
  }
  .about-intro-content-inside img {
    width: 50%;
  }
}
