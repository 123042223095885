.ques {

    margin-top: 20px;

}




.intro p {

    font: bolder !important;

    font-weight: 500;

}




@media only screen and (max-width: 320px) {

    .icon1515 .flex {

        margin-top: -110px;

        margin-bottom: 10px;

    }

    .lol12 {

        margin-top: -110px;

    }

    .first{
        margin-bottom: -110px;
    }


}

@media only screen and (min-width: 481px) and (max-width: 600px){
    .icon1515 .flex {

        margin-top: -110px;

        margin-bottom: 10px;

    }

    .lol12 {

        margin-top: -110px;

    }

    .first{
        margin-bottom: -110px;
    }


}

@media only screen and (min-width: 601px) and (max-width: 768px){

    .icon1515 .flex {

        margin-top: -110px;

        margin-bottom: 10px;

    }

    .lol12 {

        margin-top: -110px;

    }

    .first{
        margin-bottom: -110px;
    }


}


@media only screen and (min-width: 769px) and (max-width: 992px){

    .icon1515 .flex {

        margin-top: -110px;

        margin-bottom: 10px;

    }

    .lol12 {

        margin-top: -110px;

    }

    .first{
        margin-bottom: -110px;
    }

}

@media only screen and (min-width: 993px)and (max-width:1187px){

    .icon1515 .flex {

        margin-top: -110px;

        margin-bottom: 10px;

    }

    .lol12 {

        margin-top: -110px;

    }

    .first{
        margin-bottom: -110px;
    }

}

@media screen and (min-width: 321px) and (max-width: 480px) {

    .icon1515 .flex {

        margin-top: -110px;

        margin-bottom: 10px;

    }

    .lol12 {

        margin-top: -110px;

    }

    .first{
        margin-bottom: -110px;
    }
    

        

    

}

