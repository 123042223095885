.blog-card {
  width: 100%;
  height: 100%;
  background-color: #fdfdfd;
  height: 250px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.5;
  padding-left:20px;
  padding-right:20px;
  /* padding-bottom: 20px; */
}

.blog-card:hover {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
}

.blog-card > p:first-of-type {
  margin-top: 20px;
  color: #3a3632 !important;
}

.blog-card > p:last-of-type {
  margin-bottom: 20px;
}
.blog-card a {
  color: #3a3632;
}
.blog-card a:hover {
  color: #3a3632;
}
@media only screen and (max-width: 1200px) {
  .blog-card {
    padding: 0px 15px;
  }
}
@media only screen and (max-width: 767px) {
  .blog-card {
    padding: 0px 10px;
  }
}

.blogs-load-more-button {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  width: fit-content;
  color: #fdfdfd;
  border: 1px solid #3a3632;
  background-color: #3a3632 !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 12px 50px;
  letter-spacing: 1.5px;
  margin-top: 20px;
  font-weight: 700;
  font-size: 14px;
}

.blogs-load-more-button:hover {
  background-color: transparent !important;
  color: #3a3632;
}

@media screen and (max-width: 767px) {
  .blogs-load-more-button {
    background-color: transparent;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding: 10px 30px;
    margin-top: 20px;
    font-weight: 700;
    font-size: 12px;
  }
}
