.home-page-blogs {
  width: 100% !important;
  padding: 6.631vh 13.02vw;
  background-color: #f4f4f4;
}
.home-page-blogs-cards-div {
  width: 100%;
  padding-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;
}
.blogs-pagination {
  width: 100% !important;
  display: flex;
  justify-content: center;
  margin-top: 0px;
}
.load-more-hidden {
  display: none !important;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .home-page-blogs {
    padding: 3.315vh 6.51vw !important;
  }
  .home-page-blogs-cards-div {
    grid-template-columns: 1fr;
  }
  .home-page-blogs h2 {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media only screen and (max-width: 600px) and (orientation: landscape) {
  .home-page-blogs {
    padding: 3.315vw 6.51vh !important;
  }
  .home-page-blogs-cards-div {
    grid-template-columns: 1fr;
  }
  .home-page-blogs h2 {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .home-page-blogs {
    padding: 3.978vh 8.138vw !important;
  }
  .home-page-blogs-cards-div {
    grid-template-columns: 1fr;
  }
}
@media only screen and (min-width: 600px) and (orientation: landscape) {
  .home-page-blogs {
    padding: 3.978vw 8.138vh !important;
  }
  .home-page-blogs-cards-div {
    grid-template-columns: 1fr 1fr;
  }
  .home-page-blogs {
    padding: 4.641vh 9.765vw !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .home-page-blogs {
    padding: 4.641vh 9.765vw !important;
  }
  .home-page-blogs-cards-div {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (min-width: 768px) and (orientation: landscape) {
  .home-page-blogs {
    padding: 4.641vw 9.765vh !important;
  }
  .home-page-blogs-cards-div {
    grid-template-columns: 1fr 1fr;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .home-page-blogs {
    padding: 5.305vh 11.393vw !important;
  }
  .home-page-blogs-cards-div {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 1150px) {
  .home-page-blogs {
    padding: 5.305vh 11.393vw !important;
  }
  .home-page-blogs-cards-div {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .home-page-blogs-cards-div {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .home-page-blogs {
    padding: 6.631vh 13.02vw !important;
  }
}
