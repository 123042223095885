.OurPurpose-div {
  height: 100vh;
  min-height: 100vh;
  width: 100% !important;
  padding: 6.631vh 13.02vw !important;
  background-color: #fdfdfd;
}

.OurPurpose-content {
  height: calc(100% - 6.631vh) !important;
  transform: translateY(40px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}

.OurPurpose-button {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: 5px solid transparent;
  width: fit-content;
  color: #3a3632;
  border: 1px solid #3a3632;
  background-color: transparent !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 12px 50px;
  letter-spacing: 1.5px;
  margin-top: 20px;
  font-weight: 700;
  font-size: 14px;
  background: linear-gradient(to left, transparent 50%, #3a3632 50%) right !important;
  background-size: 200% 100% !important;
}

.OurPurpose-button:hover {
  background-position: left !important;
  color: #fdfdfd !important;
}

.OurPurpose-div img {
  height: 30vh;
}
.our-purpose-inside-div {
  text-align: center;
}
.our-purpose-inside-div .h4-subheading {
  margin: 0 !important;
  padding: 0 !important;
}
.our-purpose-inside-div .h2-heading {
  margin: 0 !important;
  padding: 0 !important;
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .OurPurpose-button {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding: 12px 50px;
    margin-top: 20px;
    font-weight: 700;
    font-size: 14px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .OurPurpose-div {
    padding: 3.315vh 6.51vw !important;
  }
  .OurPurpose-content {
    height: calc(100% - 3.315vh);
    transform: translateY(40px);
  }
  .OurPurpose-div img {
    width: 60%;
    height: auto;
  }
}
@media only screen and (max-width: 600px) and (orientation: landscape) {
  .OurPurpose-div {
    min-height: 100vw;
    padding: 3.315vw 6.51vh !important;
  }
  .OurPurpose-content {
    height: calc(100% - 3.315vw);
    transform: translateY(40px);
  }
  .OurPurpose-div img {
    height: 30vw;
    width: auto;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .OurPurpose-div {
    padding: 3.978vh 8.138vw !important;
  }
  .OurPurpose-content {
    height: calc(100% - 3.978vh);
    transform: translateY(40px);
  }
}
@media only screen and (min-width: 600px) and (orientation: landscape) {
  .OurPurpose-div {
    min-height: 100vw;
    padding: 3.978vw 8.138vh !important;
  }
  .OurPurpose-content {
    height: calc(100% - 3.978vw);
    transform: translateY(40px);
  }
  .OurPurpose-div img {
    height: 30vw;
    width: auto;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .OurPurpose-div {
    min-height: 100vh;
    padding: 4.641vh 9.765vw !important;
  }
  .OurPurpose-content {
    height: calc(100% - 4.641vh);
    transform: translateY(40px);
  }
  .OurPurpose-div img {
    height: 30vh;
    width: auto;
  }
}
@media only screen and (min-width: 768px) and (orientation: landscape) {
  .OurPurpose-div {
    min-height: 100vw;
    padding: 4.641vw 9.765vh !important;
  }
  .OurPurpose-content {
    height: calc(100% - 4.641vw);
    transform: translateY(40px);
  }
  .OurPurpose-div img {
    height: 30vw;
    width: auto;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .OurPurpose-div {
    min-height: 100vh;
    padding: 5.305vh 11.393vw !important;
  }
  .OurPurpose-content {
    height: calc(100% - 5.305vh);
    transform: translateY(40px);
  }
  .OurPurpose-div img {
    height: 30vh;
    width: auto;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .OurPurpose-div {
    padding: 6.631vh 12.02vw !important;
  }
  .OurPurpose-content {
    height: calc(100% - 6.631vh);
    transform: translateY(40px);
  }
}
