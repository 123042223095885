.about-home-purpose {
  width: 100% !important;
  padding: 6.631vh 13.02vw !important;
  background-color: #fdfdfd;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
  text-align: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .about-home-purpose {
    padding: 3.315vh 6.51vw !important;
  }
  .about-home-purpose img{
    width: 60%;
    height:auto;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 600px) and (orientation: landscape) {
  .about-home-purpose {
    padding: 3.315vw 6.51vh !important;
  }
  .about-home-purpose img{
    width: 40%;
    height:auto;
    margin-bottom: 10px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .about-home-purpose {
    padding: 3.978vh 8.138vw !important;
  }
  .about-home-purpose img{
    width: 70%;
    height:auto;
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 600px) and (orientation: landscape) {
  .about-home-purpose {
    padding: 3.978vw 9.765vh !important;
  }
  .about-home-purpose img{
    width: 25%;
    height:auto;
    margin-bottom: 10px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .about-home-purpose {
    padding: 4.641vh 9.765vw !important;
  }
  .about-home-purpose img{
    width: 192px;
    height:auto;
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 768px) and (orientation: landscape) {
  .about-home-purpose {
    padding: 4.641vw 9.765vh !important;
  }
  .about-home-purpose img{
    width: 25%;
    height:auto;
    margin-bottom: 10px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .about-home-purpose {
    padding: 5.305vh 11.393vw !important;
  }
  .about-home-purpose img{
    width: 192px;
    height:auto;
    margin-bottom: 10px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .about-home-purpose {
    padding: 6.631vh 13.02vw !important;
  }
}
