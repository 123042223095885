.services-application-modern-content {
  width: 100% !important;
  padding: 6.631vh 13.02vw !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  background-color: #fdfdfd;
}
.services-application-modern-content-right {
  width: 40%;
}
.services-application-modern-content-left {
  width: 50%;
}

.services-application-modern-content-left img {
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .services-application-modern-content {
    padding: 3.315vh 6.51vw !important;
    flex-direction: column;
    gap: 20px;
  }
  .services-application-modern-content-right {
    width: 100%;
  }
  .services-application-modern-content-left {
    width: 90%;
  }
}
@media only screen and (max-width: 600px) and (orientation:landscape){
  .services-application-modern-content {
    padding: 3.315vw 6.51vh !important;
    flex-direction: column;
    gap: 20px;
  }
  .services-application-modern-content-right {
    width: 100%;
  }
  .services-application-modern-content-left {
    width: 90%;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .services-application-modern-content {
    padding: 3.978vh 8.138vw !important;
    flex-direction: column;
    gap: 20px;
  }
  .services-application-modern-content-right {
    width: 100%;
  }
  .services-application-modern-content-left {
    width: 90%;
  }
}
@media only screen and (min-width: 600px) and (orientation: landscape) {
  .services-application-modern-content {
    padding: 3.978vw 8.138vh !important;
    flex-direction: row !important;
  }
  .services-application-modern-content-right {
    width: 50%;
  }
  .services-application-modern-content-left {
    width: 50%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .services-application-modern-content {
    padding: 4.641vh 9.765vw !important;
    flex-direction: column;
    gap: 20px;
  }
  .services-application-modern-content-right {
    width: 100%;
  }
  .services-application-modern-content-left {
    width: 90%;
  }
}
@media only screen and (min-width: 768px) and (orientation: landscape) {
  .services-application-modern-content {
    padding: 4.641vw 9.765vh !important;
    flex-direction: row !important;
  }
  .services-application-modern-content-right {
    width: 50%;
  }
  .services-application-modern-content-left {
    width: 50%;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .services-application-modern-content {
    padding: 5.305vh 11.393vw !important;
    flex-direction: row !important;
  }
  .services-application-modern-content-right {
    width: 40%;
  }
  .services-application-modern-content-left {
    width: 50%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .services-application-modern-content {
    padding: 6.631vh 13.02vw !important;
    flex-direction: row !important;
  }
  .services-application-modern-content-right {
    width: 40%;
  }
  .services-application-modern-content-left {
    width: 50%;
  }
}

.h2-1 {
  display: none;
}
@media only screen and (max-width: 600px) {
  .h2-1 {
    display: block;
  }
  .h2-2 {
    display: none;
  }
}
@media only screen and (min-width: 600px) {
  .h2-1 {
    display: block;
  }
  .h2-2 {
    display: none;
  }
}
@media only screen and (min-width: 600px) and (orientation: landscape) {
  .h2-1 {
    display: none;
  }
  .h2-2 {
    display: block;
  }
}
@media only screen and (min-width: 768px) {
  .h2-1 {
    display: block;
  }
  .h2-2 {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (orientation: landscape) {
  .h2-1 {
    display: none;
  }
  .h2-2 {
    display: block;
  }
}
@media only screen and (min-width: 992px) {
  .h2-1 {
    display: none;
  }
  .h2-2 {
    display: block;
  }
}
