.services-homescreen {
  min-height: 100vh;
  background-color: #d6932c;
  width: 100% !important;
  padding: 6.631vh 13.02vw !important;
}

.services-content-container {
  margin-top: calc(30px + 6.631vh);
  display: flex;
  /* height: 100%; */
  height: calc(100% - 6.631vh - 6.631vh - 30px);
  width: 100%;
}
.services-home-img {
  width: 50%;
}
.services-home-img img {
  width: 70%;
  height: auto;
}
.services-home-content {
  width: 50%;
}

#services-home-img {
  order: 1 !important;
}
#services-home-content {
  order: 2 !important;
}
@media only screen and (max-width: 600px) {
  .services-homescreen {
    padding: 3.315vh 6.51vw !important;
  }
}
@media only screen and (max-width: 600px) and (orientation:landscape){
  .services-homescreen {
    padding: 3.315vh 6.51vw !important;
  }
}
@media only screen and (min-width: 600px) {
  .services-homescreen {
    padding: 3.978vh 8.138vw !important;
  }
}
@media only screen and (min-width: 600px) and (orientation:landscape){
  .services-homescreen {
    padding: 3.978vw 8.138vh !important;
  }
}
@media only screen and (min-width: 768px) {
  .services-homescreen {
    padding: 4.641vh 9.765vw !important;
  }
}
@media only screen and (min-width: 767px) and (orientation:landscape){
  .services-homescreen {
    padding: 4.641vw 9.765vh !important;
  }
}
@media only screen and (max-width: 992px) {
  .services-homescreen {
    min-height: 100vh !important;
  }
  .services-content-container {
    gap: 30px;
    flex-direction: column !important;
    justify-content: space-between;
    height: calc(100% - 6.631vh - 6.631vh - 30px) !important;
  }
  .services-home-img {
    width: 100% !important;
    height: fit-content !important;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
  .services-home-img img {
    width: 90% !important;
    height: auto !important;
  }
  .services-home-content {
    width: 100% !important;
    text-align: center;
  }
  #services-home-img {
    order: 2 !important;
  }
  #services-home-content {
    order: 1 !important;
  }
}

@media only screen and (max-width: 992px) and (orientation: landscape) {
  .services-homescreen {
    min-height: 100vh !important;
  }
  .services-content-container {
    gap: 30px;

    flex-direction: column !important;
    justify-content: space-between;
    height: calc(100% - 6.631vh - 6.631vh - 30px) !important;
  }
  .services-home-img {
    width: 100% !important;
    height: fit-content !important;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
  .services-home-img img {
    width: 50% !important;
    height: auto !important;
  }
  .services-home-content {
    width: 100% !important;
    text-align: center;
  }
  #services-home-img {
    order: 2 !important;
  }
  #services-home-content {
    order: 1 !important;
  }
}

@media only screen and (min-width: 992px) {
  .services-homescreen {
    height: 100vh;
    background-color: #d6932c;
    width: 100%;
    padding: 5.305vh 11.393vw !important;
  }
  .services-content-container {
    margin-top: calc(30px + 6.631vh);
    display: flex;
    height: calc(100% - 6.631vh - 6.631vh - 30px);
    width: 100%;
  }
  .services-home-img {
    width: 50% !important;
  }
  .services-home-img img {
    width: 70%;
    height: auto;
  }
  .services-home-content {
    width: 50%;
  }
  #services-home-img {
    order: 1 !important;
  }
  #services-home-content {
    order: 2 !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .services-homescreen {
    height: 100vh;
    background-color: #d6932c;
    width: 100%;
    padding: 6.631vh 13.02vw !important;
  }
  .services-content-container {
    margin-top: calc(30px + 6.631vh);
    display: flex;
    height: calc(100% - 6.631vh - 6.631vh - 30px);
    width: 100%;
  }
  .services-home-img {
    width: 50% !important;
  }
  .services-home-img img {
    width: 70%;
    height: auto;
  }
  .services-home-content {
    width: 50%;
  }
  #services-home-img {
    order: 1 !important;
  }
  #services-home-content {
    order: 2 !important;
  }
}
