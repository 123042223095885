.side-navigation-bar {
  background-color: transparent;
  position: fixed;
  bottom: 50vh;
  transform: translateY(50%);
  left: 0;
  z-index: 2000;
  color: #fdfdfd;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: left;
  width: 13.02vw;
  height: 70vh;
  padding: 20px;
  padding-left: 10px;
}
.side-navigation-links {
  border-top: 1px solid #fdfdfd;
  width: 4vw !important;
  height: 5vh !important;
}
.side-navigation-links-active {
  border-top: 4px solid #fdfdfd;
  width: 7vw !important;
  /* transition: all 0.3s ease-in-out; */
  height: fit-content !important;
  border-top: none;
}
.side-navigation-links-active .side-navigation-bar-link-text {
  display: block;
  /* visibility: visible; */
  opacity: 1;
  /* font-weight: 600 !important; */
  border-top: 4px solid #fdfdfd;
  /* animation-delay: 0.1s !important; */
  transition: all 350ms ease-out;
}
.side-navigation-bar-link-text {
  /* transition: opacity 0.3s ease-out; */
  color: #fdfdfd;
  padding-top: 4px;
  font-size: 12px;
  /* visibility: hidden; */
  opacity: 0;
  font-weight: 400;
}

.side-navigation-links:hover {
  border-top: 4px solid #fdfdfd;
  border-top: none;
  width: 7vw !important;
  height: 5vh !important;
}
.side-navigation-links:hover p {
  /* visibility: visible; */
  opacity: 1;
  border-top: 4px solid #fdfdfd;
  transition: all 0.3s ease-out;
  /* border-top: 4px solid #fdfdfd; */
}
.side-navigation-links-active:hover {
  height: fit-content !important;
}

.black-navigation .side-navigation-bar-link-text,
.black-navigation .side-navigation-links-active,
.black-navigation .side-navigation-bar {
  color: #3a3632 !important;
  border-color: #3a3632 !important;
}
.black-navigation .side-navigation-links {
  border-color: #3a3632;
}
.side-nav-bar-half {
  display: none !important;
}
@media only screen and (max-width: 992px) {
  .side-navigation-bar {
    display: none !important;
  }
}

@media only screen and (min-width: 992px) {
  .side-navigation-bar {
    height: 76vh;
  }
}

@media only screen and (min-width: 1200px) {
  .side-navigation-bar {
    height: 70vh;
  }
}